<template>
  <div>
    <div class="air__utils__heading">
      <h5>Configuraciones</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!-- Barra de Búsqueda -->
    <detail-catalog-bar @getData="getCatalogs"
                        :sortByOptions="sortByOptions"
                        @searchValue="searchValue = $event"
                        @sortByValue="sortByValue = $event"
                        @sortDirectionValue="sortDirectionValue = $event"
                        @perPageValue="perPageValue = $event"
                        @currentPageValue="currentPageValue = $event"
    />

    <a-table
      :dataSource="data"
      :columns="columns"
      :loading="loading"
      :row-key="record => record.table"
      :pagination="pagination"
      @change="getCatalogs"
      >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" class='custom-filter-dropdown'>
        <a-input
          v-ant-ref="c => searchInput = c"
          :placeholder="`Buscar ${column.dataIndex}`"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block;"
        />
        <a-button
          type='primary'
          @click="() => handleSearch(selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >Buscar</a-button>
        <a-button
          @click="() => handleReset(clearFilters)"
          size="small"
          style="width: 90px"
        >Limpiar</a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type='search' :style="{ color: filtered ? '#108ee9' : undefined }" />
      <template slot="customRender" slot-scope="text">
        <span v-if="searchText">
          <template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{fragment}}</mark>
            <template v-else>{{fragment}}</template>
          </template>
        </span>
        <template v-else>{{text}}</template>
      </template>
      <a slot="action" slot-scope="data">
        <b-button variant="primary" pill :to="{ name: 'detail-settings', params: { table: data.table }}">
          <b-icon icon="arrow-right-circle-fill"></b-icon> Ver Detalle
        </b-button>
      </a>
    </a-table>

  </div>
</template>

<script>
import { objectArray, resolvePagination } from '@/helpers/jsonApiTransformer'
import DetailCatalogBar from '../catalogs/components/searchBar'

const routes = [
  {
    name: 'home',
    breadcrumbName: 'Inicio',
    bIcon: 'house-fill',
  },
  {
    name: 'catalogs',
    breadcrumbName: 'Configuraciones',
    aIcon: '',
  },
]
export default {
  name: 'TablesAntdCustomFilter',
  components: {
    DetailCatalogBar,
  },
  data () {
    return {
      routes,
      // Varialbles para la tabla
      data: [],
      searchText: '',
      searchInput: null,
      columns: [{
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
        scopedSlots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'customRender',
        },
        onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus()
            }, 0)
          }
        },
      },
      {
        title: 'Área de Configuración',
        dataIndex: 'initial_settings_type',
        key: 'initial_settings_type',
        scopedSlots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'customRender',
        },
        onFilter: (value, record) => record.description.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus()
            })
          }
        },
      },
      {
        title: 'Descripción',
        dataIndex: 'description',
        key: 'description',
        scopedSlots: {
          filterDropdown: 'filterDropdown',
          filterIcon: 'filterIcon',
          customRender: 'customRender',
        },
        onFilter: (value, record) => record.description.toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
          if (visible) {
            setTimeout(() => {
              this.searchInput.focus()
            })
          }
        },
      },
      {
        title: 'Acciones',
        dataIndex: '',
        key: 'x',
        scopedSlots: {
          customRender: 'action',
        },
      }],
      pagination: {
        current: 1,
        pageSize: 15,
        from: 0,
        to: 0,
        total: 30,
        lastPage: 0,
      },
      loading: false,
      // Varialbles para la tabla
      // <!-- Barra de Control -->
      searchValue: '',
      sortByValue: '',
      sortByDefaultValue: 'name',
      sortDirectionValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'name', text: 'Nombre' },
        { value: 'initial_settings_type', text: 'Tipos' },
      ],
      perPageValue: 15,
      currentPageValue: 1,
      // <!-- Barra de Control -->
    }
  },
  computed: {
    sortDirection() {
      if (this.sortDirectionValue === '') {
        return ''
      } else if (this.sortDirectionValue === '-') {
        return '-'
      } else {
        return ''
      }
    },
    sortValue() {
      if (this.sortByValue === '') {
        return 'name'
      } else {
        return this.sortByValue
      }
    },
  },
  methods: {
    handleSearch (selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    getCatalogs(paginationEvent = {}) {
      if (Object.keys(paginationEvent).length !== 0) {
        this.pagination.current = paginationEvent.current
        this.pagination.pageSize = paginationEvent.pageSize
      }

      this.loading = true
      this.$store.dispatch('jv/get', ['initialSettings', {
        params: {
          'page[number]': (Object.keys(paginationEvent).length !== 0) ? `${this.pagination.current}` : `${this.currentPageValue}`,
          'page[size]': `${this.perPageValue}`,
          sort: `${this.sortDirection}${this.sortValue}`,
          'filter[search]': `${this.searchValue}`,
        },
      }])
        .then(response => {
          this.loading = false
          const jsonApiPagination = response.jsonApi.json.meta.page
          const tableData = response
          this.data = objectArray(tableData)
          this.pagination = resolvePagination(jsonApiPagination)
        })
        .catch(errors => {
          console.log(errors)
        })
    },
  },
  mounted() {
    this.getCatalogs()
  },
}
</script>

<style scoped>
  .custom-filter-dropdown {
    padding: 8px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }

  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
</style>
